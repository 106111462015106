@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

*,
body {
  font-family: "Outfit" !important;
}


h2 {
  font-weight: 600;
}





/* Dashboard Layout.css */

.layout-container {
  display: flex;
  height: 100vh;
  background-color: white;
  /* background-color: #F3F6F9; */
  flex-direction: column;
}

@media (min-width: 768px) {
  .layout-container {
    flex-direction: row;
    overflow: hidden;
  }
}

.sidebar-container {
  width: 100%;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .sidebar-container {
    width: 14rem;
    border-right: 1px solid gainsboro
  }
}

.content-container {
  flex-grow: 1;
  padding: 1.5rem;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .content-container {
    padding: 0rem 1rem 2rem;
  }
}


.sidebar-container {
  overflow-y: auto;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.sidebar-container::-webkit-scrollbar {
  display: none !important;
}